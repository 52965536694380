<template>
	<div>
		<div class="layout-topbar shadow-4">
			<div class="layout-topbar-left">
				<router-link class="layout-topbar-logo" to="/">
					<div class="header-logo adomanyLogo">
						<div>Váci</div>
						<div>Egyházmegye</div>
						<div>Karitász</div>
					</div>
				</router-link>
				<a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
					<i class="pi pi-ellipsis-v fs-large"></i>
				</a>
			</div>

			<div class="layout-topbar-right" :class="{'layout-topbar-mobile-active': mobileTopbarActive}">
				<div class="layout-topbar-actions-right">
				</div>
				<div class="layout-topbar-actions-left">
					<MegaMenu :model="items" class="layout-megamenu"></MegaMenu>
					<ul class="layout-topbar-items" style="margin-left: 1rem;">
						<li class="layout-topbar-item app">
							<a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'apps')" v-ripple>
								<div v-if="getCart">
									<img  src="/images/cart.png" />
								</div>
								<div v-else>
									<img src="/images/cart-open.png" />
								</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div style="
			position: fixed;
			width: 82px; 
			height: 128px;	
			right: 4rem; 
			top: 26px; 
			background: url(images/caritas_logo_fix.png); 
			z-index:1000;
		">
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
		data() {
			return {
				searchText: '',
				items: [
					{
						label: 'Adomány',
						to: '/',
					},
					{
						label: 'Rólunk',
						to: '/rolunk',
					},
					{
						label: 'Kapcsolat',
						to: '/kapcsolat',
					},
				],
			}
		},
		props: {
			horizontal: {
				type: Boolean,
				default: false
			},
			topbarMenuActive: {
				type: Boolean,
				default: false
			},
			activeTopbarItem: String,
			mobileTopbarActive: Boolean,
			searchActive: Boolean
		},
		methods: {
			onSearchContainerClick(event) {
				this.$emit("search-click", event);
			},
			changeSearchActive(event) {
				this.$emit('search-toggle', event);
			},
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			onTopbarItemClick(event, item) {
				if(item === 'search') {
					this.$emit('search-toggle', event);
				}

				this.$emit('topbaritem-click', {originalEvent: event, item: item});
			},
			onTopbarMobileButtonClick(event) {
				this.$emit('topbar-mobileactive', event);
			},
			onRightPanelButtonClick(event) {
				this.$emit('rightpanel-button-click', event);
			},
			onSearchKeydown(event) { 
				if (event.keyCode == 13) {
					this.$emit('search-toggle', event);
				}
			},
			onEnter() {
				if (this.$refs.searchInput) {
					this.$refs.searchInput.$el.focus();
				}
			}
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-items animated fadeInDown', {
					'topbar-items-visible': this.topbarMenuActive
				}];
			},
			isRTL() {
				return this.$appState.RTL;
			},
			currentRouteName() {
				return this.$route.name;
			},
			getCart() {
				return this.$appState.myCart;
			}
		},
	}
</script>
