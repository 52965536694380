// import {createRouter, createWebHashHistory} from 'vue-router';
import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'adomany',
        exact: false,
        component: () => import('./components/Adomany.vue'),
        // props: (route) => ({ hiba: route.query.hiba }),
        meta: {
            breadcrumb: [{ label: 'Adomány' }],
        },
    },
    {
        path: '/kapcsolat',
        name: 'kapcsolat',
        exact: false,
        component: () => import('./components/Kapcsolat.vue'),
        meta: {
            breadcrumb: [{ label: 'Kapcsolat' }],
        },
    },
    {
        path: '/rolunk',
        name: 'rolunk',
        exact: false,
        component: () => import('./components/Rolunk.vue'),
        meta: {
            breadcrumb: [{ label: 'Rólunk' }],
        },
    },
    {
        path: '/koszonjuk',
        name: 'koszonjuk',
        exact: false,
        component: () => import('./components/Koszonjuk.vue'),
        meta: {
            breadcrumb: [{ label: 'Köszönjük' }],
        },
    },    
    {
        path: '/hiba',
        name: 'hiba',
        exact: false,
        component: () => import('./components/Hiba.vue'),
        meta: {
            breadcrumb: [{ label: 'Hiba' }],
        },
    },      
];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
    // mode: 'history',
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;

/*
function removeHash(to) {
    if (to.hash) return { path: to.path, query: to.query, hash: '' }
  }
*/

router.beforeEach((to, from, next) => {
    if (typeof to.query.hiba === 'undefined') {
        if (typeof to.query.koszonjuk === 'undefined') {
            next()
        } else {
            next('/koszonjuk')
        }
    } else {
        next('/hiba')
    }
});